<div  class="row   bg-white border-bottom box-shadow mb-3" style="box-shadow: 0 0.25rem 0.75rem #002244 !important ">
  <div class="col-4">
    <h3 class="my-auto" *ngIf="isPilotFacility== null || isPilotFacility=='' || isPilotFacility=='N'"
      style="margin-left:2rem"><a class="navbar-brand" href="#"><img width="420" height="100"
          src="assets/images/ODA_Logo_340x88_Transparent.png" /></a></h3>
    <h3 class="my-auto" *ngIf="isPilotFacility=='Y'" style="margin-left:2rem"><a class="navbar-brand" href="#"><img
          width="420" height="100" src="assets/images/ODA_EXCEL_HORI.png" /></a></h3>
  </div>
  <div class="col-4">
    <h4 style="text-align: center; color: #002244 !important ;vertical-align: middle;
line-height: 90px;" class="title">Care Center</h4>
  </div>
  <div class="col-4">
   <div style="float: right;">
    <div *ngIf="contextFacility" style="margin-top: 10px; margin-right: 5px;">
      <div style="font-weight: bold;font-size: 15px;color: #670808;">{{ contextFacility?.name }} - {{
        contextFacility?.stateId}} </div>
      <div style=" font-size: 13px;color:gray; ">
        <div>{{ contextFacility?.address1 }} </div>
        <div>{{ contextFacility?.city }}, {{ contextFacility?.state }} {{ contextFacility?.zip }}</div>
      </div>
  </div>
  <div *ngIf="contextPharmacy">
    <div style="    font-weight: bold;font-size: 15px;color: #670808;">{{ contextPharmacy?.name }} </div>
    <div style=" font-size: 13px;color:gray; ">
      <div>{{ contextPharmacy?.address }} </div>
      <div>{{ contextPharmacy?.city }}, {{ contextPharmacy?.state }} {{ contextPharmacy?.zip }}</div>
    </div>
  </div>
   </div> <br>
   <div *ngIf="!contextFacility"  style="float: right; color: maroon;">
        <button style="float: right;" mat-button [matMenuTriggerFor]="menu">Help <i class="material-icons">help_outline</i></button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item href="assets/UserGuides/Facility_Profile_User_Guide.pdf" target="_blank">Facility Profile user guide</a>
        <a mat-menu-item href="assets/UserGuides/Excel_Program_User_Guide.pdf" target="_blank">Excel Program user guide</a>
      </mat-menu>
  </div>
</div>
</div>