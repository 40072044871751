
<div class="container" style="padding-top:100px;margin-bottom:200px;">

  <div class="row row-cols-3">

    <div class="col offset-4">

      <div class=" card mat-elevation-z5 mt-5" [formGroup]="authForm">

        <div class="card-header d-flex justify-content-center">
          <mat-card-title>
            Login
          </mat-card-title>
        </div>

        <div class="card-body">

          <div class="row ">

            <mat-form-field class="col" appearance="outline">
              <mat-label>User Name</mat-label>
              <input id="userName" matInput placeholder="User Name" formControlName="UserName" type="text" required>

              <mat-error id="userNameRequiredError" *ngIf="authForm.get('UserName').value == ''">
                User Name is <strong>required</strong>
              </mat-error>
              <mat-error id="userNameInvalidError" *ngIf="authForm.get('UserName')?.invalid && authForm.get('UserName').value != ''">
                Please enter valid characters
              </mat-error>
            </mat-form-field>

          </div>

          <div class="row ">

            <mat-form-field class="col" appearance="outline">
              <mat-label>Password</mat-label>
              <input id="password" matInput placeholder="Password" formControlName="Password" type="password" required>

              <mat-error id="passwordRequiredError" *ngIf="authForm.get('Password').value == ''">
                Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>

          </div>

          <div class="row justify-content-center align-content-center">
            <button id="submit" mat-raised-button color="primary" type="submit" (click)="submitForm()" [disabled]="isSubmitting">
              Login
            </button>
          </div>
          <br/>
          <div class="row justify-content-center align-content-center">
            <button id="forgotPassword" mat-raised-button color="warn" routerLink="forgot-password" type="submit">
              Forgot Password
            </button>
          </div>


        </div>

        <mat-progress-bar *ngIf="isSubmitting" mode="indeterminate"></mat-progress-bar>

      </div>

    </div>
    

  </div>

  



</div>

<!--<div style="text-align:center; vertical-align:middle;height:300px;width:100%;margin-top:100px;">
  <a class="mr-3" mat-raised-button color="primary" (click)="loginAsPharmacy()">Login as Pharmacy</a>
  <a class="mr-3" mat-raised-button color="primary" (click)="loginAsFacility()">Login as Facility</a>
  <a mat-raised-button color="primary" (click)="LoginAsAdmin()">Login as Admin</a>
</div>-->
